
import { defineComponent, ref, PropType, computed } from 'vue';
import { ElPopover } from 'element-plus';
import {
  ISearchConfigItem,
} from '../types';

export default defineComponent({
  name: 'AdvancedSearchButton',
  props: {
    searchConfig: {
      type: Array as PropType<Array<ISearchConfigItem>>,
      required: true,
    },
  },
  setup(props) {
    const popoverEl = ref<InstanceType<typeof ElPopover>>();
    const visible = ref(false);
    const handleChangeVisible = (val: boolean) => {
      visible.value = val;
    };
    const config = computed(() => props.searchConfig || []);
    return {
      popoverEl,
      handleChangeVisible,
      visible,
      config,
    };
  },
  render() {
    return (
      <div>
        <el-popover
          visible={this.visible}
          width="500px"
          placement="bottom-end"
          onShow={() => this.handleChangeVisible(true)}
          onHide={() => this.handleChangeVisible(false)}
          popper-class="advanced_popper"
          ref="popoverEl"
          v-slots={{
            reference: () => (
              <div class="btn_layout">
                <el-button
                  type="primary"
                  onClick={() => this.handleChangeVisible(true)}
                >高级搜索</el-button>
              </div>
            ),
            default: () => (
              <div class="advanced_container">
                <div class="advanced_container_header">
                  <div class="title">高级搜索</div>
                  <div class="close iconfont icon_clear" onClick={() => this.handleChangeVisible(false)}/>
                </div>
                <div class="advanced_container_ul">
                  {this.config.map(v => v.render())}
                </div>
              </div>
            ),
          }}
        >
        </el-popover>
      </div>
    );
  },
});
