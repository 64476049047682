import { useMenuStore } from '@/store/menu';
import { useAccountStore } from '@/store/account';
import http from '@/core/http';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import router from '@/router';

NProgress.configure({ showSpinner: true });

const hasRoute = (route: RouteLocationNormalized) => {
  // if (process.env.NODE_ENV === 'development') {
  //   return true;
  // }
  const { authMenuList } = useMenuStore();
  return authMenuList.includes(route.name as string);
};

// eslint-disable-next-line
const pathHandler = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  // 需要登录 但不需要校验权限的路由名称
  const skipAuthRouteName = [
    'NoAuth',
  ];
  const { token, currentCompany } = useAccountStore();
  const isLogin = token;
  const selectedCompany = currentCompany;
  const isLoginPath = to.path === '/login';
  const noMatchRouter = to.matched.length === 0;
  const { auth = true } = to.meta || {};
  const noAuth = !auth;
  const needValidAuth = !skipAuthRouteName.includes(to.name as string);
  const redirectUrl = encodeURIComponent(to.fullPath);
  const validators = [
    // 没有匹配对应路由 直接调整404
    { rule: noMatchRouter, params: { path: '/redirect/404', replace: true } },
    // 登陆过 && 进入登陆页面 调整首页
    { rule: isLogin && isLoginPath, params: { path: '/' } },
    // 不需要登陆 直接下一步
    { rule: noAuth },
    // 已登录 && 没有选择租户 && 不是选择租户页面
    {
      rule: isLogin && !selectedCompany && to.name !== 'SelectCompany',
      params:
        {
          name: 'SelectCompany',
          query: { redirect: redirectUrl },
          replace: true,
        },
    },
    // 已登录 && 选择租户页面
    { rule: isLogin && to.name === 'SelectCompany' },
    // 没有登陆 && 不是登陆页面 调整登陆页面
    { rule: !isLogin && !isLoginPath, params: { path: '/recommend', query: { redirect: redirectUrl }, replace: true } },
    // 登陆过 && 有权限 直接下一步
    { rule: isLogin && hasRoute(to) },
    // 登陆过 && 没有权限 跳过
    { rule: isLogin && !hasRoute(to) && needValidAuth, params: { path: '/redirect/403', replace: true } },
  ];

  const found = validators.find((valid) => {
    return valid.rule;
  });
  if (found && found.params) {
    next(found.params);
  } else {
    next();
  }
};

// 处理当前用户信息
const handleCurrentUser = async (to: RouteLocationNormalized) => {
  const accountStore = useAccountStore();
  // 已登陆
  if (accountStore.token && to.fullPath.toUpperCase() !== '/LOGIN') {
    // 获取用户信息
    await accountStore.getAccountInfo();
    // 存在租户id才获取资源
    if (accountStore.currentCompany) {
      // 获取菜单
      await useMenuStore().getMenus();
    }
  }
};

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const { auth = true } = to.meta || {};
  if (auth) {
    await handleCurrentUser(to);
  }
  pathHandler(to, from, next);
});

router.afterEach(() => {
  NProgress.done();
});
