
import { defineComponent, ref, toRefs, watch } from 'vue';

export default defineComponent({
  name: 'inputNumber',
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: [Number, String],
      default: 0,
    },
    /**
     * 最大值
     */
    min: {
      type: Number,
      default: -Infinity,
    },
    /**
     * 最大值
     */
    max: {
      type: Number,
      default: Infinity,
    },
    /**
     * 小数点位数
     */
    precision: {
      type: Number,
      default: Infinity,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { min, max, precision, modelValue } = toRefs(props);
    const inputValue = ref<number|string>('');
    const onInput = (val: string) => {
      const newVal = String(val).trim();
      inputValue.value = newVal;
      if (inputValue.value !== '' && !Number.isNaN(newVal)) {
        if (Number(newVal) > max.value || Number(newVal) < min.value) {
          return;
        }
        if (String(newVal).indexOf('.') !== -1) {
          const arr = String(newVal).split('.');
          const percentCount = arr[1];
          if (percentCount.length > precision.value) {
            return;
          }
        }
        inputValue.value = newVal;
        emit('update:modelValue', newVal);
        emit('change', newVal);
      }
    };
    const onChange = () => {
      let newVal: number|string = Number(inputValue.value);
      if (inputValue.value !== '' && !Number.isNaN(newVal)) {
        if (newVal > max.value) {
          newVal = max.value;
        } else if (newVal < min.value) {
          newVal = min.value;
        }
        if (String(newVal).indexOf('.') !== -1) {
          const arr = String(newVal).split('.');
          const percentCount = arr[1];
          if (percentCount.length > precision.value) {
            newVal = Number(`${arr[0]}.${arr[1].substr(0, precision.value)}`);
          }
        }
        inputValue.value = newVal;
      } else {
        inputValue.value = '';
        newVal = '';
      }
      emit('update:modelValue', newVal);
      emit('change', newVal);
    };
    watch(() => modelValue.value, (val) => {
      if (inputValue.value !== val) {
        inputValue.value = val;
      }
    }, {
      immediate: true,
    });
    return {
      onInput,
      inputValue,
      onChange,
    };
  },
  render() {
    return (
      <el-input
        modelValue={this.inputValue}
        onInput={this.onInput}
        onBlur={this.onChange}
        disabled={this.disabled}
        placeholder={this.placeholder}
        v-slots={this.$slots}
      />
    );
  },
});
