
import { defineComponent, SetupContext, PropType } from 'vue';
import { IElRow, IElCol } from '@/types/index.d';

interface RowConfig {
  props?: IElRow;
  [key: string]: any;
}

interface ColConfig {
  props?: IElCol | null;
  [key: string]: any;
}

export default defineComponent({
  name: 'ResponsiveRow',
  props: {
    row: {
      type: Object as PropType<RowConfig>,
    },
    col: {
      type: Object as PropType<ColConfig>,
    },
  },
  setup(props, { slots }: SetupContext) {
    return () => {
      const { col, row } = props;
      const childList = (slots.default && slots.default()) || [];

      // 处理默认参数
      const genCol = { ...(col || { props: null }) };
      const defaultColProps = { xs: 24, sm: 12, md: 8, lg: 8, xl: 6 } as IElCol;
      genCol.props = genCol.props || defaultColProps;

      type TChild = typeof childList;

      const colChildList = [] as TChild;
      const getColChild = (_childList: TChild) => {
        if (Array.isArray(_childList)) {
          _childList.forEach((child) => {
            const _type = child?.type as any;
            if (_type?.name === 'ElCol') {
              colChildList.push(child);
              return;
            }

            if (!_type?.name) {
              const symbolType = Object(_type).toString();
              if (symbolType === 'Symbol(Comment)') {
                return;
              }
            }
            if (Array.isArray(child?.children) && child?.children?.length) {
              getColChild(child.children as TChild);
              return;
            }
            // 对特定el-col自定义，传入col
            // 例：<el-form-item :col="{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }">
            child.props?.col && (genCol.props = child.props?.col);
            colChildList.push(
              (<el-col { ...genCol.props }>
                { child }
              </el-col>),
            );
          });
        }
      };
      getColChild(childList);
      return (
        <el-row { ...(row || {}) }>
          {
            colChildList
          }
        </el-row>
      );
    };
  },
});
