import { defineStore } from 'pinia';
import { MenuState, IFlatMenuItem, IMenuItem } from './types';
import { shareMenuConfig } from './config';
import { getUserResource } from '@/api/menu';
import { RESOURCE_TYPE_ENUM } from '@/api/menu/type';

export const useMenuStore = defineStore({
  id: 'menu',
  state: (): MenuState => ({
    menus: [],
    flatMenus: [],
    isGetMenu: false,
    authMenuList: [],
    authButtonList: [],
  }),
  getters: {},
  actions: {
    async getMenus(type: 'normal'|'share' = 'normal') {
      try {
        if (this.isGetMenu) return;
        let menus: IMenuItem[] = [];
        const authMenuList: string[] = [];
        const authButtonList: string[] = [];
        if (type === 'share') {
          menus = [...shareMenuConfig];
        } else {
          const { data = [] } = await getUserResource();
          menus = [...data];
        }
        // const res = await getMenus();
        // const { data = {} } = res;
        // const { resourceTreeVos = [], resourceFlatVos = [] } = data;
        this.menus = [...menus];
        const flat: IFlatMenuItem[] = [];
        const deep = (menuTree: IMenuItem[], pIds: string[]) => {
          menuTree.forEach((it) => {
            if (
              [
                RESOURCE_TYPE_ENUM.MENU,
                RESOURCE_TYPE_ENUM.CATALOGUE,
              ].includes(it.resourceType)
              && it.frontEndUrl
            ) {
              authMenuList.push(it.frontEndUrl);
              flat.push({
                resourceName: it.resourceName,
                frontEndUrl: it.frontEndUrl,
                pIds: [...pIds],
              });
            }
            if (it.children?.length && it.resourceType !== RESOURCE_TYPE_ENUM.BUTTON) {
              deep(it.children, [...pIds, it.frontEndUrl]);
            } else if (it.resourceType === RESOURCE_TYPE_ENUM.BUTTON) {
              if (it.frontEndUrl) {
                authMenuList.push(...it.frontEndUrl.split(','));
              }
              if (it.resourceCode) {
                authButtonList.push(it.resourceCode);
              }
            }
          });
        };
        deep(menus, []);
        this.flatMenus = flat;
        this.authMenuList = [...new Set(authMenuList)];
        this.authButtonList = [...new Set(authButtonList)];
        this.isGetMenu = true;
      } catch (error) {
        // 获取菜单失败 清空菜单
        this.clearMenus();
        throw Error('获取资源失败');
      }
    },
    clearMenus() {
      this.menus = [];
      this.authMenuList = [];
      this.authButtonList = [];
      this.flatMenus = [];
      this.isGetMenu = false;
    },
  },
});
