export default [
  {
    path: '/select-company',
    name: 'SelectCompany',
    component: () => import('./views/select-company/index.vue'),
    hidden: true,
    meta: {
      noCache: true,
      title: '选择租户',
    },
  },
  {
    path: '/plugin-manage',
    name: 'PluginManage',
    component: () => import('@/layouts/default.vue'),
    children: [
      {
        path: 'download',
        name: 'PluginManageDownload',
        component: () => import('./views/plugin-download/index.vue'),
        hidden: true,
        meta: {
          noCache: true,
          auth: false,
          title: '妙款采集工具下载页',
        },
      },
    ],
  },
  {
    path: '/accout',
    component: () => import('@/layouts/normal.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('./views/account/login.vue'),
        meta: {
          auth: false,
          title: '登陆',
        },
      },
    ],
  },
  {
    path: '/recommend',
    name: 'Recommend',
    component: () => import('./views/recommend/index.vue'),
    meta: {
      auth: false,
      title: '妙款介绍',
    },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('./views/error-page/404.vue'),
    hidden: true,
    meta: {
      auth: false,
      title: '404',
    },
  },
  {
    path: '/403',
    name: 'NoAuth',
    component: () => import('./views/error-page/403.vue'),
    hidden: true,
    meta: {
      title: '403',
    },
  },
  {
    path: '/401',
    name: 'NoLogin',
    component: () => import('./views/error-page/401.vue'),
    hidden: true,
    meta: {
      auth: false,
      title: '401',
    },
  },
  {
    path: '/redirect',
    component: () => import('@/layouts/main.vue'),
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('./views/redirect/index.vue'),
        meta: {
          auth: false,
        },
      },
    ],
  },
];
