import { App } from 'vue';
import AppPage from '@/components/app-page';
import Icon from '@/components/icon';
import Pagination from '@/components/pagination';
import Upload from '@/components/upload';
import QuerySelect from '@/components/query-select';
import InputNumber from '@/components/input-number';
import ResponsiveRow from '@/components/responsive-row';
import CustomTable from '@/components/custom-table';
import CustomImage from '@/components/custom-image/index';
import Tabs from '@/components/tabs/index';
import Empty from '@/components/empty/index';
import MkSelect from '@/components/mk-select';
import MkDatePicker from '@/components/mk-date-picker';
import CustomSelect from '@/components/custom-select';
import CustomCascader from '@/components/custom-cascader';
import CustomDatePicker from '@/components/custom-date-picker';
import CustomNumberRange from '@/components/custom-number-range';
import ListPagination from '@/components/list-pagination';
import FilterBar from '@/components/filter-bar';
import SortBar from '@/components/sort-bar';
import VirtualSelect from '@/components/virtual-select';

export default function componentsInstall(app: App) {
  app.use(VirtualSelect);
  app.use(Upload);
  app.use(CustomImage);
  app.use(Pagination);
  app.use(AppPage);
  app.use(QuerySelect);
  app.use(InputNumber);
  app.use(ResponsiveRow);
  app.use(Tabs);
  app.use(Empty);
  app.use(MkSelect);
  app.use(MkDatePicker);
  app.use(CustomTable);
  app.use(CustomSelect);
  app.use(CustomCascader);
  app.use(CustomDatePicker);
  app.use(CustomNumberRange);
  app.use(Icon);
  app.use(ListPagination);
  app.use(FilterBar);
  app.use(SortBar);
}
