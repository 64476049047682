import { loginBySSO, logoutBySSO, getUserInfo } from '@/api/account';
import http from '@/core/http';
import { ENV_PREFIX } from '@/core/http/env';
import { defineStore } from 'pinia';
import { useMenuStore } from '../menu';
import { IState, IStoreAccount } from './types';
import { SYSTEM_ENUM } from '@/constant/global';
import { ICompanyInfoItem } from '@/api/account/type';
import { USER_TYPE_ENUM } from '@/constant/system-info';

const accountStorage = {
  tokenKey: `${SYSTEM_ENUM.MOKEN}-token-${ENV_PREFIX}`,
  companyKey: `${SYSTEM_ENUM.MOKEN}-companyKey-${ENV_PREFIX}`,
  getToken(): string {
    const token = localStorage.getItem(this.tokenKey);
    return token || '';
  },
  setToken(token: string) {
    localStorage.setItem(this.tokenKey, token);
  },
  removeToken() {
    return localStorage.removeItem(this.tokenKey);
  },
  getCompany(): string {
    const token = localStorage.getItem(this.companyKey);
    return token || '';
  },
  setCompany(token: string) {
    localStorage.setItem(this.companyKey, token);
  },
  removeCompany() {
    return localStorage.removeItem(this.companyKey);
  },
};

const initToken = () => {
  const token = accountStorage.getToken();
  http.account = Object.assign({}, http.account || {}, { ssoToken: token });
  return token;
};

export const useAccountStore = defineStore({
  id: 'account',
  state: (): IState => ({
    token: initToken(),
    account: null,
    currentCompany: null,
  }),
  // 定义计算属性
  getters: {},
  // 定义修改state数据函数
  actions: {
    /* 设置当前租户 */
    setCurrentCompany(currentCompany: ICompanyInfoItem) {
      this.currentCompany = currentCompany;
      http.account = Object.assign(
        { ssoToken: this.token! },
        http.account || {},
        { currentCompanyInfo: currentCompany },
      );
      this.updateAccountUserName(currentCompany.username);
      accountStorage.setCompany(currentCompany.companyId);
    },
    setCurrentAccount(account: IStoreAccount) {
      this.account = account;
      http.account = Object.assign({ ssoToken: this.token! }, http.account || {}, account);
    },
    updateAccountUserName(username: string) {
      http.account!.username = username;
      this.account!.username = username;
    },
    setCurrentToken(token: string) {
      this.token = token;
      http.account = Object.assign({}, http.account || {}, { ssoToken: token });
      accountStorage.setToken(token);
    },
    async loginBySSO(account: {
      username: string;
      password: string;
    }) {
      try {
        const assignAccount = Object.assign({}, {
          loginType: 1,
          loginFromSystemCode: SYSTEM_ENUM.MOKEN,
        }, account);

        const { data } = await loginBySSO(assignAccount);
        this.setCurrentToken(data.ssoToken);
        // 请求用户信息参数
        await this.getAccountInfo();
      } catch (error) {
        console.log(error);
        throw new Error('登录失败');
      }
    },
    // 处理租户
    handleCompany() {
      const account = this.account!;
      // 判断租户列表
      if (account.companyInfoList && account.companyInfoList.length && account.companyInfoList.length === 1) {
        this.setCurrentCompany(account.companyInfoList[0]);
        return;
      }
      const companyId = accountStorage.getCompany();
      // 租户Id
      if (companyId) {
        const findItem = account.companyInfoList.find(it => it.companyId === companyId);
        if (findItem) {
          this.setCurrentCompany(findItem);
        }
      }
      // 存在多个租户 并且内存没有租户的情况下 后续在后续路由拦截器会处理跳转
    },
    // 获取用户信息
    async getAccountInfo() {
      try {
        if (this.account) return;
        const { data } = await getUserInfo();
        this.setCurrentAccount({
          ...data,
          type: USER_TYPE_ENUM.REAL,
        });
        this.handleCompany();
      } catch (e) {
        console.log(e);
        throw new Error('获取用户信息失败');
      }
    },
    async logout(isExpiration = false) {
      const menuStore = useMenuStore();
      try {
        if (!isExpiration) {
          await logoutBySSO();
        }
        this.token = '';
        this.account = null;
        this.currentCompany = null;
        accountStorage.removeToken();
        accountStorage.removeCompany();
        menuStore.clearMenus();
        http.account = null;
      } catch (error) {
        console.log('logout error', error);
      }
    },
  },
});

http.logout = async () => {
  await useAccountStore().logout();
};
