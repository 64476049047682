export default [
  {
    path: '/setting-manage',
    name: 'SettingManage',
    redirect: '/setting-manage/user-manage/list',
    component: () => import('@/layouts/main.vue'),
    children: [
      // {
      //   path: 'preset/list',
      //   name: 'SettingManagePresetList',
      //   component: () => import('./preset/views/list/index.vue'),
      //   meta: {
      //   },
      // },
      {
        path: 'user-manage/list',
        name: 'SystemManageUserManage',
        component: () => import('./user-manage/views/list/index.vue'),
        meta: {
          title: '系统设置-用户管理',
        },
      },
      {
        path: 'supplier-manage/list',
        name: 'SystemManageSupplierManage',
        component: () => import('./supplier-manage/views/list/index.vue'),
        meta: {
          title: '供应商管理',
        },
      },
    ],
  },
];
