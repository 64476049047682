
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'IconSvg',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
  },
});
