
import { defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
  name: 'MkSelect',
  emits: ['change', 'update:modelValue'],
  props: {
    modelValue: [Array, String, Number, Boolean, Object],
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    valueKey: {
      type: String,
      default: 'value',
    },
  },
  setup(props, { emit }) {
    const isTriggerShow = ref(false);
    const selectValue = ref<typeof props.modelValue>(null);
    watch(
      () => props.modelValue,
      () => {
        selectValue.value = props.modelValue;
      },
      { immediate: true },
    );
    const handleSelectChange = (val: any) => {
      selectValue.value = val;
      emit('update:modelValue', val);
      emit('change', val);
    };
    const handleVisbleChange = (val: boolean) => {
      isTriggerShow.value = val;
    };
    return {
      selectValue,
      isTriggerShow,
      handleSelectChange,
      handleVisbleChange,
    };
  },
});
