import { App } from 'vue';
import type { SFCWithInstall } from '@/types/utils';

import Select from './packages/select.vue';

Select.install = (app: App): void => {
  app.component(Select.name, Select);
};

export default Select as SFCWithInstall<typeof Select>;
