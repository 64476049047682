export default [
  {
    path: '/information-manage',
    name: 'InformationManage',
    redirect: '/information-manage/shop-manage/list',
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        path: 'shop-manage/list',
        name: 'InformationManageShopManageList',
        component: () => import('./shop-manage/views/list/index.vue'),
        meta: {
          title: '淘款-淘店-全部店铺',
        },
      },
      {
        path: 'shop-manage/detail',
        name: 'InformationManageShopManageDetail',
        component: () => import('./shop-manage/views/detail/index.vue'),
        meta: {
          title: '淘款-淘店-店铺详情',
          activeMenu: 'InformationManageShopManageList',
        },
      },
    ],
  },
];
