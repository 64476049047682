export enum SYSTEM_ENUM {
  /** 妙款 */
  MOKEN = 'MOKEN',
}

// 渠道类型
export enum CHANNEL_ENUM {
  /** 海外 值为 1｜2｜3 */
  HAIWAI = '1',
  /** 跨境商品 misscircle */
  MISSCIRCLE = '2',
  /** 跨境商品 ohpolly */
  OHPOLLY = '3',
  /** 淘品 */
  TAOPIN = '4',
  /** 抖品 */
  DOUPIN = '5',
  /** instagram */
  INSTAGRAM = '6',
  /** 小红书(预留字段，暂未使用) */
  XIAO_HONG_SHU = '7',
  /** 新浪微博(预留字段，暂未使用) */
  SINA_WEIBO = '8',
  /** 秀场 vogue */
  VOGUE = '9',
  /** 秀场 新浪时尚sina */
  SINA_FASHION = '10',
  /** 秀场 fashionNetwork */
  FASHION_NETWORK = '11',
  /** 京东 */
  JD = '12',
  /** 唯品会 */
  VIP_SHOP = '13',
}
export const CHANNEL_LIST = [
  { value: CHANNEL_ENUM.TAOPIN, label: '淘品' },
  { value: CHANNEL_ENUM.DOUPIN, label: '抖品' },
  { value: CHANNEL_ENUM.HAIWAI, label: '海外' },
];

// 升降序方式
export enum ORDER_ENUM {
  /** 升序 */
  ASC = 'ascending',
  /** 降序 */
  DESC = 'descending',
}

// 是否
export enum YES_OR_NO_ENUM {
  YES = '1',
  NO = '0',
}
