
import { defineComponent, ref, PropType } from 'vue';
import { ElPopover, ElForm } from 'element-plus';
import { FormRulesMap } from 'element-plus/packages/form/src/form.type';

export default defineComponent({
  name: 'customNumberRange',
  emits: [
    'update:rangeStart',
    'update:rangeEnd',
  ],
  props: {
    label: {
      type: String,
      default: '',
    },
    /**
     * 最大值
     */
    min: {
      type: Number,
      default: -Infinity,
    },
    /**
     * 最大值
     */
    max: {
      type: Number,
      default: Infinity,
    },
    /**
     * 小数点位数
     */
    precision: {
      type: Number,
      default: Infinity,
    },
    rangeStart: {
      type: [String, Number],
      required: true,
    },
    rangeEnd: {
      type: [String, Number],
      required: true,
    },
    unit: {
      type: String,
      default: '',
    },
    rule: {
      type: Array as PropType<FormRulesMap['']>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const popoverEl = ref<InstanceType<typeof ElPopover>>();
    const formEl = ref<InstanceType<typeof ElForm>>();
    const date = ref(['', '']);
    const formData = ref<{
      range: Array<string|number>;
    }>({
      range: [],
    });
    const visible = ref(false);
    const handleChangeVisible = (val: boolean) => {
      if (val) {
        formData.value.range = [props.rangeStart, props.rangeEnd];
      } else {
        formData.value.range = ['', ''];
      }
      visible.value = val;
    };
    const handleConfirm = async () => {
      try {
        await formEl.value?.validate();
        popoverEl.value?.hide();
        emit('update:rangeStart', formData.value.range[0]);
        emit('update:rangeEnd', formData.value.range[1]);
      } catch (error) {
        console.log(error);
      }
    };
    return {
      formEl,
      formData,
      popoverEl,
      handleChangeVisible,
      visible,
      date,
      handleConfirm,
      rules: {
        range: props.rule,
      },
    };
  },
});
