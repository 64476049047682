import { onUpdated, defineComponent, PropType, ref, watch, nextTick } from 'vue';
import { IProps } from './type.d';
import Sortable from 'sortablejs';
import { ElTable } from 'element-plus';
import styles from './style.module.scss';

/**
 * 公共表格组件
 */
export default defineComponent({
  name: 'CustomTable',
  props: {
    data: {
      type: Array as PropType<IProps['data']>,
      required: true,
      default: () => [],
    },
    columns: {
      type: Array as PropType<IProps['columns']>,
      required: true,
      default: () => [],
    },
    canDrag: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const currentKey = ref(0);
    const tableEl = ref<InstanceType<typeof ElTable> | null>(null);
    const init = () => {
      if (!props.canDrag) return;
      const el = tableEl.value?.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      Sortable.create(el, {
        // sort: false,
        handle: `.${styles.dragTarget}`,
        forceFallback: true,
        animation: 150,
        ghostClass: `${styles.active}`,
        dragClass: `${styles.active}`,
        selectedClass: `${styles.active}`,
        // 拖拽结束执行,evt执向拖拽的参数
        onEnd: async (evt) => {
          // 判断是否重新排序
          if (evt.oldIndex !== evt.newIndex) {
            const { data } = props;
            const row = data.splice(evt.oldIndex!, 1)[0];
            data.splice(evt.newIndex!, 0, row);
            currentKey.value += 1;
            setTimeout(async () => {
              const els: HTMLElement[] = tableEl.value?.$el.querySelectorAll(
                '.el-table__body-wrapper > table > tbody > tr',
              );
              els.forEach((v) => {
                v.setAttribute('draggable', 'true');
              });
              // init();
            });
          }
        },
      });
    };
    watch(
      [() => props.columns, () => props.canDrag],
      ([columns, canDrag], [oldColumns, oldCanDrag]) => {
        if (canDrag !== oldCanDrag) {
          currentKey.value += 1;
          return;
        }
        if (canDrag && (!oldColumns || columns.length !== oldColumns.length)) {
          currentKey.value += 1;
        }
      },
      {
        immediate: true,
      },
    );
    watch(
      () => props.data,
      () => {
        if (props.canDrag) {
          currentKey.value += 1;
        }
      },
      {
        immediate: true,
      },
    );
    onUpdated(async () => {
      if (props.canDrag) {
        await nextTick();
        init();
      }
    });
    return {
      tableEl,
      currentKey,
    };
  },
  render() {
    const renderTableItem = (column: IProps['columns'][0]) => {
      const renderFun = column.render;
      const key = column.prop;
      if (renderFun) {
        return {
          default: ({ row, $index }: any) => {
            if ($index === -1) return null;
            return renderFun(row, $index);
          },
        };
      }
      if (key) {
        return {
          default: ({ row }: any) => <div>{row[key]}</div>,
        };
      }
      return undefined;
    };
    return (
      <div>
        <el-table
          ref="tableEl"
          border={true}
          size="small"
          {...this.$attrs}
          key={this.currentKey}
          data={this.data}
          v-slots={{
            empty: () => (
              <empty/>
            ),
          }}
        >
          {this.canDrag && (
            <el-table-column
              width="60px"
              align="center"
              v-slots={{
                default: () => <i class={`${styles.dragTarget} el-icon-rank`} title="按住拖拽" />,
              }}
            />
          )}
          {this.columns.map(v => (
            <el-table-column
              key={v.label}
              type={v.type}
              label={v.label}
              width={v.width}
              minWidth={v.minWidth}
              labelClassName={v.labelClassName}
              fixed={v.fixed}
              align={v.align}
              show-overflow-tooltip={v.ellipsis}
              render-header={v.renderHeader}
              v-slots={renderTableItem(v)}
              selectable={
                v.selectable ? (row: any, index: number) => v.selectable(row, index) : null
              }
            />
          ))}
        </el-table>
      </div>
    );
  },
});
