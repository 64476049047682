
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { ElMain, ElHeader, ElFooter } from 'element-plus';
import { getColStyle, getColClassList } from './utils';

export default defineComponent({
  name: 'AppPage',
  props: {
    mainConfig: {
      type: Object,
      default: () => ({}),
    },
    laside: {
      type: Object,
      default() {
        return {};
      },
    },
    raside: {
      type: Object,
      default() {
        return {};
      },
    },
    fheader: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    const contentHeight = ref(0);
    const collapse = ref(false);

    const contentMainRef = ref<typeof ElMain | null>(null);
    const contentHeaderRef = ref<typeof ElHeader | null>(null);
    const contentFooterRef = ref<typeof ElFooter | null>(null);
    const contentWrapperRef = ref<typeof ElMain | null>(null);

    const computedContentHeight = () => {
      if (contentMainRef.value) {
        const { clientHeight } = contentMainRef.value.$el;
        let headerHeight = 0;
        let footerHeight = 0;
        if (contentHeaderRef.value) {
          headerHeight = parseInt(getComputedStyle(contentHeaderRef.value.$el).height, 10);
        }
        if (contentFooterRef.value) {
          footerHeight = parseInt(getComputedStyle(contentFooterRef.value.$el).height, 10);
        }
        contentHeight.value = clientHeight - headerHeight - footerHeight;
      }
    };

    let timer: number | null = null;
    const windowResizeCallBack = () => {
      if (timer) return;
      timer = window.setTimeout(() => {
        timer = null;
        computedContentHeight();
      }, 200);
    };
    onMounted(() => {
      computedContentHeight();
      window.addEventListener('resize', windowResizeCallBack, false);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', windowResizeCallBack, false);
    });

    return {
      contentHeight,
      collapse,

      contentMainRef,
      contentHeaderRef,
      contentWrapperRef,
      contentFooterRef,
      computedContentHeight,
      windowResizeCallBack,
    };
  },
  render() {
    const renderCollapse = () => {
      const { showCollapse } = this.fheader;
      return (
        <el-button
          v-show={showCollapse}
          type="text"
          onClick={() => {
            this.collapse = !this.collapse;
            this.windowResizeCallBack();
          }}
        >
          {this.collapse ? '收起' : '展开'}
          <i class={`margin-left-5 el-icon-arrow-${this.collapse ? 'up' : 'down'}`} />
        </el-button>
      );
    };

    // 渲染固定头部
    const renderFHeader = () => {
      const { row = {}, col } = this.fheader;
      const fheaderVNode = this.$slots.fheader?.({ collapse: this.collapse });

      if (fheaderVNode instanceof Array) {
        fheaderVNode.forEach((vNodeItem: any) => {
          const vNodeChildKeys = Object.keys(vNodeItem.children || {});
          if (vNodeItem.type?.name === 'ElForm') {
            vNodeChildKeys.forEach((key) => {
              const _renderFunc = vNodeItem.children[key];

              if (typeof _renderFunc !== 'function') {
                return;
              }

              vNodeItem.children[key] = function renderDef() {
                const children = _renderFunc();
                // 给row的数据
                const rowPropsData = {
                  gutter: 10,
                  ...row,
                };
                const _renderChild = () => {
                  // 这块处理展开按钮
                  {
                    const _getShowCollapseVNode = (vNode: any) => {
                      const childs = vNode.children.default();
                      vNode.children.default = () => {
                        return [
                          ...childs,
                          renderCollapse(),
                        ];
                      };

                      return vNode;
                    };

                    let len = children?.length || 0;
                    while (len > 0) {
                      len -= 1;
                      const lastElFormItemChild = children[len];
                      if (
                        lastElFormItemChild?.type?.componentName === 'ElFormItem'
                            // 保证最后一个form-item可被正确渲染
                            && typeof lastElFormItemChild?.children?.default === 'function'
                      ) {
                        _getShowCollapseVNode(lastElFormItemChild);
                        break;
                      }
                    }
                  }

                  const colClass = getColClassList(
                    (col || { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 }),
                    rowPropsData,
                  );
                  const colStyle = getColStyle(rowPropsData);

                  return children.map((vNode: any) => {
                    return (
                      vNode?.type?.componentName !== 'ElFormItem'
                        ? vNode
                        : <div
                            class={['el-col', colClass]}
                            style={colStyle}
                          >
                          {
                            vNode
                          }
                        </div>
                    );
                  });
                };

                return [
                  (
                    <el-row { ...rowPropsData }>
                      {
                        _renderChild()
                      }
                    </el-row>
                  ),
                ];
              };
            });
          }
        });
      }

      return (
        <el-header class="page-fixed-header" v-show={!!fheaderVNode}>
          {
            /**
             * 保证vue node节点插入
             */
            fheaderVNode || (<div></div>)
          }
        </el-header>
      );
    };

    // render Main header
    const renderMainHeader = () => {
      if (!this.$slots.header) return null;
      this.windowResizeCallBack();
      return <el-header class="page-main-header" ref="contentHeaderRef">
        {this.$slots.header()}
      </el-header>;
    };

    // 渲染主要内容区域
    const renderMain = () => {
      const defaultConfig = {
        padding: '16px',
        borderRadius: '4px',
        backgroundColor: '#fff',
      };
      const config = {
        ...defaultConfig,
        ...this.mainConfig,
      };
      return (
        <el-main
          class="page-content"
          ref="contentWrapperRef"
          style={config}
        >
          {this.$slots.main && (
            <el-main
              ref="contentMainRef"
              class="page-main"
            >
              { renderMainHeader() }
              {this.$slots.main({ height: this.contentHeight })}
              {this.$slots.footer && (
                <el-footer
                  class="page-footer"
                  ref="contentFooterRef"
                >{this.$slots.footer()}</el-footer>
              )}
            </el-main>
          )}
        </el-main>
      );
    };

    // 渲染固定底部
    const renderFFooter = () => {
      if (!this.$slots.ffooter) return null;
      this.windowResizeCallBack();
      return <el-footer class="page-fixed-footer">{this.$slots.ffooter()}</el-footer>;
    };

    // 渲染左侧边栏
    const renderLAside = () => {
      if (!this.$slots.laside) return null;
      const laside = this.laside || {};
      return (
        <el-aside class="page-left-aside" { ...laside }>
          {this.$slots.laside()}
        </el-aside>
      );
    };

    // 渲染右侧边栏
    const renderRAside = () => {
      if (!this.$slots.raside) return null;
      const raside = this.raside || {};
      return (
        <el-aside class="page-right-aside" { ...raside }>
          {this.$slots.raside()}
        </el-aside>
      );
    };
    return (
      <div class="app-page" style="height:calc(100vh - 107px);">
        {this.$slots.default && this.$slots.default()}
        <el-container>
          { renderLAside() }
          <el-container class="page-center-container" direction="vertical">
            { renderFHeader() }
            { renderMain() }
            { renderFFooter() }
          </el-container>
          { renderRAside() }
        </el-container>
      </div>
    );
  },
});
