
import { defineComponent, PropType } from 'vue';
import {
  IFilterTagsItem,
  ISearchConfigItem,
} from './types';
import IconClose from '@/assets/images/icon_tags_close.png';
import IconClean from '@/assets/icon_qc.png';
import AdvancedSearchButton from './advanced-search-button/index.vue';

export default defineComponent({
  name: 'FilterBar',
  components: {
    AdvancedSearchButton,
  },
  emits: [
    'reset',
    'removeItem',
  ],
  props: {
    tags: {
      type: Array as PropType<IFilterTagsItem[]>,
      default: () => [],
    },
    searchConfig: {
      type: Array as PropType<ISearchConfigItem[]>,
      default: () => [],
    },
    advancedSearchConfig: {
      type: Array as PropType<ISearchConfigItem[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const handleReset = () => {
      emit('reset');
    };
    const handleRemoveItem = (key: string) => {
      emit('removeItem', key);
    };
    return {
      handleReset,
      handleRemoveItem,
    };
  },
  render() {
    return (
      <div class="conditions-container">
        <div
          class="conditions-container_item"
          v-show={this.tags.length}
        >
          <div class="conditions-container_item_label">已选择：</div>
          <div class="tags_layout">
            {this.tags.map(v => (
              <div class="item" key={v.key}>
                <div class="txt">{v.label}：{v.content}</div>
                <img
                  class="close"
                  src={IconClose}
                  onClick={() => this.handleRemoveItem(v.key)}
                />
              </div>
            ))}
          </div>
          {this.tags.length !== 0 && (
            <div class="clean" onClick={this.handleReset}>
              <img class="icon" src={IconClean} />
              <div class="txt">清空筛选</div>
            </div>
          )}
        </div>
        <div
          class="conditions-container_filters"
        >
          <div class="conditions-container_filters_label">筛选：</div>
          <div class="ul">
            {this.searchConfig.map(v => v.render())}
          </div>
          {this.advancedSearchConfig.length !== 0 && (
            <advanced-search-button
              searchConfig={this.advancedSearchConfig}
            />
          )}
        </div>
      </div>
    );
  },
});
