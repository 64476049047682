
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  emits: ['size-change', 'current-change'],
  name: 'pagination',
  props: {
    size: {
      type: Number,
      default: 10,
    },
    pagerCount: {
      type: Number,
      default: 7,
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 20, 30, 50];
      },
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
    // 是否固定到顶部
    autoScroll: {
      type: Boolean,
      default: true,
    },
    targetContent: {
      type: Object as PropType<Element>,
    },
  },
  setup(props, { emit }) {
    const $ScrollTo = (val = 0) => {
      const target = props.targetContent || window;
      target.scrollTo(0, val);
    };
    const handleSizeChange = (val: number) => {
      emit('size-change', val);
      if (props.autoScroll) {
        $ScrollTo();
      }
    };
    const handleCurrentChange = (val: number) => {
      emit('current-change', val);
      if (props.autoScroll) {
        $ScrollTo();
      }
    };
    return {
      handleSizeChange,
      handleCurrentChange,
    };
  },
});
