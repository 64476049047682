
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SortBar',
  components: {},
  emits: [
    'handleItem',
  ],
  props: {
    ascKey: {
      type: String,
      default: '',
    },
    descKey: {
      type: String,
      default: '',
    },
    prop: {
      type: String,
      default: '',
    },
    order: {
      type: String,
      default: '',
    },
    sortList: {
      type: Array as PropType<{label: string;value: string;}[]>,
      default: () => [],
    },
  },
  setup(_props, { emit }) {
    const handleItem = (key: string) => {
      emit('handleItem', key);
    };
    return {
      handleItem,
    };
  },
});
