export enum EVENT_TYPE_ENUM {
  CLICK = 'click',
  PV = 'pv',
  UV = 'uv',
  PAGE_STAY = 'page_stay',
  ROUTE_STAY = 'route_stay',
  DIALOG_STAY = 'dialog_stay',
  LOGIN_STATE_CHANGE = 'login_state_change',
  EXPOSURE = 'exposure'
}
