export default [
  {
    path: '/miaokuan-manage',
    redirect: '/miaokuan-manage/miaopin/list',
    name: 'MiaoKuan',
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        path: 'miaopin/list',
        name: 'MiaoPinList',
        component: () => import('./miaopin/views/list/index.vue'),
        meta: {
          title: '妙款-妙品',
          activeMenu: 'MiaoPinList',
        },
      },
      {
        path: 'miaopin/detail',
        name: 'MiaoPinDetail',
        component: () => import('./miaopin/views/detail/index.vue'),
        meta: {
          title: '妙款-妙品详情',
          activeMenu: 'MiaoPinList',
        },
      },
      {
        path: 'blogger/list',
        name: 'BloggerList',
        component: () => import('./blogger/views/list/index.vue'),
        meta: {
          title: '妙款-博主',
          activeMenu: 'BloggerList',
        },
      },
      {
        path: 'blogger/detail',
        name: 'BloggerDetail',
        component: () => import('./blogger/views/detail/index.vue'),
        meta: {
          title: '妙款-博主详情',
          activeMenu: 'BloggerList',
        },
      },
      {
        path: 'brand/list',
        name: 'BrandList',
        component: () => import('./brand/views/list/index.vue'),
        meta: {
          title: '品牌-品牌库',
          activeMenu: 'BrandList',
        },
      },
      {
        path: 'brand/detail',
        name: 'BrandDetail',
        component: () => import('./brand/views/detail/index.vue'),
        meta: {
          title: '品款-品牌详情',
          activeMenu: 'BrandList',
        },
      },
      {
        path: 'brand/showField',
        name: 'BrandDetailShow',
        component: () => import('./common/components/shopDetail/showField/index.vue'),
        meta: {
          title: '妙款-品牌-秀场详情',
          activeMenu: 'BrandList',
        },
      },
      {
        path: 'brand/ins',
        name: 'BrandDetailIns',
        component: () => import('./common/components/shopDetail/ins/index.vue'),
        meta: {
          title: '妙款-品牌-ins详情',
          activeMenu: 'BrandList',
        },
      },
      {
        path: 'brand/taobao',
        name: 'BrandDetailTaoBao',
        component: () => import('./common/components/shopDetail/taobao/index.vue'),
        meta: {
          title: '妙款-品牌-淘宝详情',
          activeMenu: 'BrandList',
        },
      },
      {
        path: 'brand/weipin',
        name: 'BrandDetailWeiPin',
        component: () => import('./common/components/shopDetail/weipin/index.vue'),
        meta: {
          title: '妙款-品牌-唯品会详情',
          activeMenu: 'BrandList',
        },
      },
      {
        path: 'brand/jd',
        name: 'BrandDetailJingDong',
        component: () => import('./common/components/shopDetail/jd/index.vue'),
        meta: {
          title: '妙款-品牌-京东详情',
          activeMenu: 'BrandList',
        },
      },
      {
        path: 'sub/list',
        name: 'SubList',
        component: () => import('./sub/views/list/index.vue'),
        meta: {
          title: '妙款-订阅',
          activeMenu: 'SubList',
        },
      },
    ],
  },
];
