import routerConstantMap from './router-constant';
import routerAsyncMap from './router-async';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import slsLogger from '@/core/plugins/slsLogger/SLSLogger';

const routes: Array<RouteRecordRaw> = [...routerConstantMap, ...routerAsyncMap];

const router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (from.path !== '/404') {
    slsLogger.livePage();
  }
});

router.afterEach((to, from) => {
  if (to.path !== '/404') {
    slsLogger.entryPage(from.path && from.path !== '/'
      ? `${window.location.protocol}//${window.location.host}/#${from.path}` : document.referrer);
  }
});

export default router;
