
import { defineComponent, PropType, ref, computed, nextTick, onBeforeUnmount } from 'vue';
import { ElSelect } from 'element-plus';
import { debounce } from 'lodash-es';

export default defineComponent({
  name: 'VirtualSelect',
  emits: ['update:modelValue'],
  props: {
    prop: {
      type: Object as PropType<{
        label: '';
        value: '';
      }>,
    },
    options: {
      type: Array as PropType<{ [key: string]: string; }[]>,
      required: true,
    },
    modelValue: {
      type: [String, Array],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const selectRef = ref<InstanceType<typeof ElSelect>>();
    const bodyEl = ref<HTMLElement>();
    const scrollerEl = ref<HTMLElement | null>(null);
    const visible = ref(false);
    const virtualRows = ref<{ [key: string]: string; }[]>([]);
    const propKeys = computed(() => {
      return {
        label: 'label',
        value: 'value',
        ...props.prop,
      };
    });
    let startIndex = 0;
    let endIndex = 0;
    const baseLineHeight = 34;
    const contentHeight = 400;
    const scrollListener = debounce(async () => {
      const { options } = props;
      const { scrollTop } = scrollerEl.value!;
      const otherCount = Math.floor(contentHeight / baseLineHeight);
      const currentIndex = Math.floor(scrollTop / baseLineHeight);
      const computedStartIndex = Math.floor(scrollTop / baseLineHeight) - otherCount;
      const computedEndIndex = currentIndex + Math.floor(contentHeight / baseLineHeight) + otherCount;
      startIndex = computedStartIndex < 0 ? 0 : computedStartIndex;
      endIndex = computedEndIndex > options.length ? options.length : computedEndIndex;
      virtualRows.value = options.slice(startIndex, endIndex);
      bodyEl.value!.style.height = `${Math.ceil(options.length * baseLineHeight)}px`;
      bodyEl.value!.style.paddingTop = `${Math.ceil(startIndex * baseLineHeight)}px`;
      bodyEl.value!.style.paddingBottom = `${Math.ceil((options.length - endIndex) * baseLineHeight)}px`;
    }, 30);
    const init = async () => {
      await nextTick();
      scrollerEl.value = selectRef.value?.popperPaneRef.querySelector('.el-scrollbar__wrap');
      bodyEl.value = selectRef.value?.popperPaneRef.querySelector('.el-scrollbar__view');
      scrollerEl.value?.addEventListener('scroll', scrollListener);
      scrollListener();
    };
    const handleChangeVisible = (val: boolean) => {
      visible.value = val;
      if (val) {
        init();
      } else {
        scrollerEl.value?.removeEventListener('scroll', scrollListener);
      }
    };
    const handleChangeValue = (val: string|string[]) => {
      emit('update:modelValue', val);
    };
    onBeforeUnmount(() => {
      scrollerEl.value?.removeEventListener('scroll', scrollListener);
    });
    return {
      propKeys,
      selectRef,
      handleChangeVisible,
      handleChangeValue,
      visible,
      virtualRows,
    };
  },
});
