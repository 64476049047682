import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ad88f58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mk-select-wrapper" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "result-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "result"
}
const _hoisted_5 = {
  key: 1,
  class: "empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.options.find(v => v.value === _ctx.selectValue))
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.options.find(v => v.value === _ctx.selectValue)?.label), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_5, "请选择")),
      _createElementVNode("i", {
        class: _normalizeClass({
          'iconfont icon_arrow_down icon': true,
          'reverse': _ctx.isTriggerShow
        })
      }, null, 2)
    ]),
    _createVNode(_component_el_select, {
      modelValue: _ctx.selectValue,
      onVisibleChange: _ctx.handleVisbleChange,
      onChange: _ctx.handleSelectChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item[_ctx.valueKey],
            label: item[_ctx.labelKey],
            value: item[_ctx.valueKey]
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onVisibleChange", "onChange"])
  ]))
}