import qs from 'qs';
import http from '@/core/http';
import { ISSOLoginReq, ISSOLoginRes, IUserInfoRes } from './type';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

/**
 * 【SSO】登陆接口
 * @param data
 * @returns
 */
export const loginBySSO = (data: ISSOLoginReq) => {
  return http.post<ISSOLoginRes>({
    server: DOMAIN_SYSTEM_ENUM.sso,
    url: '/saas-sso/api/auth/login',
    data: qs.stringify(data),
    loading: true,
    config: {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  });
};

/**
 * 【SSO】退出登陆
 */
export const logoutBySSO = () => {
  return http.post({
    server: DOMAIN_SYSTEM_ENUM.sso,
    url: '/saas-sso/api/auth/logout',
    loading: true,
    config: {
      withCredentials: false,
    },
  });
};

/**
 * 获取用户信息
 */
export function getUserInfo() {
  return http.post<IUserInfoRes>({
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    url: '/sso-center/admin/users/info',
    loading: true,
    config: {
      withCredentials: false,
    },
  });
}
