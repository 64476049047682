
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Tabs',
  emits: ['update:modelValue', 'change'],
  props: {
    fontSize: {
      type: Number,
      default: 14,
    },
    lineHeight: {
      type: Number,
      default: 28,
    },
    border: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String as PropType<'horizontal'|'vertical'>,
      default: 'horizontal',
    },
    modelValue: {
      type: String,
      default: '',
    },
    config: {
      type: Array as PropType<{label:string;value:string;}[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const handleItem = (key: string, index: number) => {
      if (key === props.modelValue) return;
      emit('update:modelValue', key);
      emit('change', key, index);
    };
    return {
      handleItem,
    };
  },
  render() {
    return (
      <div class={`tabs_layout ${this.mode}`}>
        <div
          class={{
            ul: true,
            border: this.border,
          }}
        >
          {this.config.map((v, i) => (
            <div
              class={`li ${v.value === this.modelValue ? 'active' : ''}`}
              key={v.value}
              onClick={() => this.handleItem(v.value, i)}
            >
              <div
                class="name"
                style={{
                  fontSize: `${this.fontSize}px`,
                  lineHeight: `${this.lineHeight}px`,
                }}
              >{v.label}</div>
            </div>
          ))}
        </div>
      </div>
    );
  },
});
