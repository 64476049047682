
import {
  defineComponent,
  computed,
  PropType,
  ref,
  nextTick,
  inject,
} from 'vue';
import { ElMessage } from 'element-plus';
import promiseIterator from '@/core/utils/promise-iterator';
import { uploadFile } from '@/api/open';
import { filefuns } from 'cx-utils';
import { IFile, TFileData, IFakeFileData, ImageEl } from './type.d';
import Draggable from 'vuedraggable';

export default defineComponent({
  name: 'Upload',
  components: {
    FileList,
    Draggable,
  },
  props: {
    /**
     * 文本提示
     */
    tip: {
      type: String,
      default: '',
    },
    /**
     * fileList：文件列表
     * imageList：图片列表
     * hidden：隐藏域
     */
    type: {
      type: String as PropType<'fileList'|'imageList'|'hidden'>,
      default: 'imageList',
    },
    modelValue: {
      type: Array as PropType<(IFile | TFileData)[]>,
      default: () => ([]),
    },
    /**
     * 禁用编辑
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否支持拖拽
     */
    canDrag: {
      type: Boolean,
      default: false,
    },
    /**
     * 上传数量限制， 0为不限制
     */
    limit: {
      type: Number,
      default: Infinity,
    },
    /**
     * 是否支持多选文件
     */
    multiple: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: 'file',
    },
    accept: {
      type: String,
      default: 'image/png,image/jpeg,image/jpg',
    },
    /**
     * 文件超出个数限制时的钩子
     */
    onExceed: {
      type: Function,
      default: () => {
        ElMessage.warning('上传数量超过限制');
      },
    },
    sizeLimit: {
      type: Number,
      default: 20, // 默认是20M大小
    },
    emptyTip: {
      type: String,
      default: '未上传',
    },
    compress: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'update:modelValue', 'delete', 'upload'],
  setup(props, { emit }) {
    const elFormItem: any = inject('elFormItem', {});
    const isDraging = ref(false);
    const handleDragStart = () => {
      isDraging.value = true;
    };
    const handleDragEnd = () => {
      isDraging.value = true;
    };
    const isDroping = ref(false);
    const getFormFileData = async (file: File) => {
      let newFile = file;
      // 以图搜图-压缩
      if (props.compress) {
        const imgBlob = await filefuns.compressImg(
          file,
          (w) => {
            if (w > 356) {
              return 356 / w;
            }
            return 1;
          },
        );
        newFile = new window.File([imgBlob], file.name, { type: file.type });
      }
      const formData = new FormData();
      formData.append('files', newFile, file.name);
      return formData;
    };

    const inputRef = ref<HTMLInputElement | null>(null);
    const imagesEl = ref<ImageEl[]>([]);
    // 临时的列表
    const fakeList = ref<IFakeFileData[]>([]);
    // 用于显示文件的列表
    const showFilesList = computed(() => {
      return props.modelValue.concat(fakeList.value);
    });
    const setImageRef = (el: ImageEl, i:number) => {
      imagesEl.value[i] = el;
    };
    /**
     * 检测是否可以多选
     */
    const isMultiple = computed(() => {
      const { multiple, limit, modelValue } = props;
      if (multiple) {
        // 当前数量大于限制两张才可以
        return limit - modelValue.length >= 2;
      }
      return multiple;
    });

    const beforeUpload = (file: File) => {
      if (file.size / 1024 / 1024 > props.sizeLimit) {
        ElMessage.warning(`上传图片大小不能超过 ${parseFloat(`${props.sizeLimit}`)}MB!`);
        return false;
      }
      const { accept } = props;
      const types = accept.split(',');
      if (
        types.includes('*') === false
        && types.includes(file.type) === false
        && types.some(v => file.name.indexOf(v) !== -1) === false
      ) {
        ElMessage.warning('文件格式有误');
        return false;
      }
      return true;
    };

    /**
     * 上传
     */
    async function uploadFiles(files: FileList) {
      const { limit, modelValue: fileList, onExceed } = props;

      if (fileList.length + files.length > limit) {
        onExceed(files, fileList);
        return;
      }

      let postFileList = Array.from(files);

      if (!postFileList.length) {
        return;
      }

      if (!isMultiple.value) {
        postFileList = postFileList.slice(0, 1);
      }
      try {
        const res = await promiseIterator(postFileList, async (file) => {
          let beforeUploadRes = true;
          beforeUploadRes = await beforeUpload(file);
          if (beforeUploadRes) {
            // 当前的下标
            const index = fakeList.value.length;
            fakeList.value.push({
              loading: true,
              name: file.name,
              size: `${file.size}`,
              type: file.type,
              url: URL.createObjectURL(file),
            });

            const { data } = await uploadFile(await getFormFileData(file));
            const _fileRes = Object.assign({
              type: file.type,
            }, data[0]);
            // 替换当前临时数据
            fakeList.value.splice(index, 1, _fileRes);
            return _fileRes;
          }
          return null;
        });
        const _fileDataList = res.filter(Boolean) as TFileData[];
        if (_fileDataList.length === 0) return;
        const returnData = [...fileList, ..._fileDataList];
        emit('update:modelValue', returnData);
        emit('upload', _fileDataList);
        emit('change', returnData);
        elFormItem.formItemMitt?.emit('el.form.blur', [returnData]);
      } catch (e) {
        console.log('上传文件失败', e);
      } finally {
        nextTick(() => {
          fakeList.value = [];
        });
      }
    }

    /**
     * 处理文件选择
     */
    const handleFilePick = (files?: FileList | null) => {
      if (files?.length) {
        uploadFiles(files);
      }

      nextTick(() => {
        const _inputRef = inputRef.value!;
        _inputRef.value = '';
      });
    };

    /**
     * 处理change事件
     */
    const handleIputChange = (e: Event) => {
      const { files } = e.target as HTMLInputElement;
      handleFilePick(files);
    };
    const handleDelete = (index: number) => {
      const list = [...props.modelValue];
      const row = list.splice(index, 1);
      emit('update:modelValue', list);
      emit('delete', row);
      emit('change', list);
      elFormItem.formItemMitt?.emit('el.form.blur', [list]);
    };
    const handlePreview = (i: number) => {
      if (imagesEl.value[i]) {
        // 原生触发点击
        const _$img = imagesEl.value[i]!.$el?.childNodes[0] as HTMLElement;
        _$img?.click?.();
      }
    };
    const handleMove = (e: {
      move: {
        element: {
          url: string;
        };
      };
      oldIndex: number;
      newIndex: number;
    }) => {
      const { oldIndex, newIndex } = e;
      const { modelValue } = props;
      const list = [...modelValue];
      const row = list.splice(oldIndex!, 1)[0];
      list.splice(newIndex!, 0, row);
      emit('change', list);
      emit('update:modelValue', list);
    };
    return {
      handleMove,
      isDroping,
      handlePreview,
      setImageRef,
      inputRef,
      isMultiple,
      showFilesList,
      handleIputChange,
      handleDelete,
      uploadFiles,
      handleIputDrop(e: DragEvent) {
        if (!isDraging.value) {
          isDroping.value = false;
          const { files } = e.dataTransfer!;
          handleFilePick(files);
        }
        e.preventDefault();
      },
      handleIputDropEnter(e: DragEvent) {
        if (!isDraging.value) {
          isDroping.value = true;
        }
        e.preventDefault();
      },
      handleIputDropLeave(e: DragEvent) {
        if (!isDraging.value) {
          isDroping.value = false;
        }
        e.preventDefault();
      },
      handleDragStart,
      handleDragEnd,
    };
  },
  render() {
    return (
      <>
        {this.type === 'hidden' ? (
          <div class="hidden-container">
            {this.isDroping && (
              <div class="drap_mask">释放鼠标</div>
            )}
            <div class="layout">
              <input
                type="file"
                ref="inputRef"
                class="input"
                onDrop={ this.handleIputDrop }
                onDragenter={ this.handleIputDropEnter }
                onDragleave={ this.handleIputDropLeave }
                onChange={ this.handleIputChange }
                multiple={ this.isMultiple }
                name={ this.name }
                accept={ this.accept }
              />
            </div>
          </div>
        ) : (
          <div class="upload-container">
            {this.type === 'fileList' && (
              <div class="file-list-layout">
                {!this.disabled && (
                  <el-button
                    class="upload"
                    type="primary"
                    size="small"
                    disabled={this.showFilesList.length >= this.limit}
                  >
                    {this.showFilesList.length < this.limit && (
                      <input
                        type="file"
                        ref="inputRef"
                        class="input"
                        onDrop={ this.handleIputDrop }
                        onChange={ this.handleIputChange }
                        multiple={ this.isMultiple }
                        name={ this.name }
                        accept={ this.accept }
                      />
                    )}
                    上传文件
                  </el-button>
                )}
                {this.tip && !this.disabled ? (
                  <div class="tip">{this.tip}</div>
                ) : null}
                <div class="ul">
                  <draggable
                    tag="transition-group"
                    modelValue={this.showFilesList}
                    item-key="url"
                    onStart={this.handleDragStart}
                    onEnd={this.handleDragEnd}
                    sort={this.canDrag}
                    v-slots={{
                      item: ({ element, index }: any) => (
                        <div class="li">
                          <div class="icon el-icon-document"></div>
                          <div class="name">{element.name || element.url}</div>
                          {!this.disabled && (
                            <div class="right el-icon-close" onClick={() => this.handleDelete(index)}></div>
                          )}
                        </div>
                      ),
                      footer: () => {
                        <div class="li">111</div>;
                      },
                    }}
                    onUpdate={this.handleMove}
                  >
                  </draggable>
                  {this.showFilesList.length === 0 && this.disabled && (
                    <div class="error">{this.emptyTip}</div>
                  )}
                </div>
              </div>
            )}
            {this.type === 'imageList' && (
              <div class="image-list-layout">
                <div class="ul">
                  <draggable
                    tag="transition-group"
                    modelValue={this.showFilesList}
                    item-key="url"
                    onStart={this.handleDragStart}
                    onEnd={this.handleDragEnd}
                    sort={this.canDrag}
                    v-slots={{
                      item: ({ element, index }: any) => (
                        <div class="li">
                          <el-image
                            hide-on-click-modal={true}
                            class="cover"
                            fit="cover"
                            ref={ (el: ImageEl) => this.setImageRef(el, index) }
                            src={ element.url }
                            preview-src-list={ this.showFilesList.map(item => item.url) }
                          />
                          <div class="mask">
                            <div
                              class="preview el-icon-zoom-in"
                              onClick={() => this.handlePreview(index)}
                            />
                            {!this.disabled && (
                              <div
                                class="delete el-icon-delete"
                                onClick={() => this.handleDelete(index)}
                              />
                            )}
                          </div>
                        </div>
                      ),
                      footer: () => {
                        <div class="li">111</div>;
                      },
                    }}
                    onUpdate={this.handleMove}
                  >
                  </draggable>
                  {this.disabled && this.showFilesList.length === 0 && (
                    <div class="li">
                      <el-image
                        class="cover"
                        fit="cover"
                        src=""
                        v-slots={{
                          error: () => (
                            <div class="error">
                              <div>{this.emptyTip}</div>
                            </div>
                          ),
                        }}
                      />
                    </div>
                  )}
                  {(!this.disabled && this.showFilesList.length < this.limit) && (
                    <div class={`upload ${this.isDroping ? 'drop_border' : ''}`}>
                      <input
                        type="file"
                        ref="inputRef"
                        class="input"
                        onDrop={ this.handleIputDrop }
                        onDragenter={ this.handleIputDropEnter }
                        onDragleave={ this.handleIputDropLeave }
                        onChange={ this.handleIputChange }
                        multiple={ this.isMultiple }
                        name={ this.name }
                        accept={ this.accept }
                      />
                      {this.isDroping ? (
                        <div>释放鼠标</div>
                      ) : (
                        <div class="icon el-icon-plus"/>
                      )}
                    </div>
                  )}
                </div>
                <div class="tip">{this.tip}</div>
              </div>
            )}
          </div>
        )}
      </>
    );
  },
});
