
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'empty',
  props: {
    content: {
      type: String,
      default: '暂无数据，换个关键词搜索试试吧',
    },
  },
  setup() {},
  components: {},
});
