export default [
  {
    path: '/',
    redirect: '/resource-manage/domestic-goods/list',
  },
  {
    path: '/resource-manage',
    redirect: '/resource-manage/domestic-goods/list',
    name: 'ResourceManage',
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        path: 'domestic-goods/list',
        name: 'DomesticGoods',
        component: () => import('./domestic-goods/views/list/index.vue'),
        meta: {
          title: '淘款-淘品',
        },
      },
      {
        path: 'domestic-goods/detail/:spu',
        name: 'DomesticGoodsDetail',
        component: () => import('./domestic-goods/views/detail/index.vue'),
        meta: {
          title: '淘款-淘品详情',
          activeMenu: 'DomesticGoods',
        },
      },
      {
        path: 'style/list',
        name: 'ResourceManageStyleList',
        component: () => import('./style/views/list/index.vue'),
        meta: {
          title: '海外款-海外库',
        },
      },
      {
        path: 'search-image/list',
        name: 'SearchImageList',
        component: () => import('./search-image/views/list/index.vue'),
        hidden: true,
        meta: {
          title: '图库-以图搜图',
          activeMenu: 'ResourceManage',
        },
      },
    ],
  },
];
