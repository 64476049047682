import { App } from 'vue';
import type { SFCWithInstall } from '@/types/utils';

import DatePicker from './packages/date-picker.vue';

DatePicker.install = (app: App): void => {
  app.component(DatePicker.name, DatePicker);
};

export default DatePicker as SFCWithInstall<typeof DatePicker>;
