import { App } from 'vue';
import { useMenuStore } from '@/store/menu';
import { numberfuns, datefuns } from 'cx-utils';

interface LabelListItem<T = string> {
    value: T;
    label: string;
}

function getLabelByVal<T>(arr: LabelListItem<T>[], val: string | number) {
  const item = arr.find(it => String(it.value) === String(val));
  return item?.label || '';
}
export const $filters = {
  // 获取枚举标签
  getEnumLabel<T>(list: LabelListItem<T>[], val: string | number): string {
    return getLabelByVal<T>(list, val);
  },
  // 时间格式化
  formatTime(date: string | number | Date, fmt = 'YYYY-MM-DD HH:mm:ss'): string {
    return datefuns.formatTime(date, fmt);
  },
  // 金额格式化
  toThousands(num: number | string, defaultValue = '-'): string {
    return numberfuns.toThousands(num, defaultValue);
  },
};
export const has = (code: string):boolean => {
  const { authButtonList } = useMenuStore();
  return authButtonList.includes(code);
};
export const hasRoute = (name: string):boolean => {
  const { authMenuList } = useMenuStore();
  return authMenuList.includes(name);
};
export type IFilters = typeof $filters;
export type IHas = typeof has;
export type IHasRoute = typeof hasRoute;
export default {
  install(app: App) {
    const { globalProperties } = app.config;

    globalProperties.$has = has;

    globalProperties.$filters = $filters;
    globalProperties.$hasRoute = hasRoute;
  },
};
