export default [
  {
    path: '/design-task',
    redirect: '/design-task/list',
    name: 'DesignTaskManage',
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        path: 'list',
        name: 'DesignTaskManageList',
        component: () => import('./design-task/views/list/index.vue'),
        meta: {
          title: '妙想-想法',
        },
      },
      {
        path: 'detail/:id',
        name: 'DesignTaskManageDetail',
        component: () => import('./design-task/views/detail/index.vue'),
        meta: {
          title: '妙想-想法详情 ',
          activeMenu: 'DesignTaskManageList',
        },
      },
      {
        path: 'share-detail/:id',
        name: 'DesignTaskDetailFromShare',
        component: () => import('./design-task/views/detail/index.vue'),
        meta: {
          title: '妙想-想法详情 ',
          activeMenu: 'DesignTaskManageList',
          auth: false,
        },
      },
      {
        path: 'editor/:id',
        name: 'DesignTaskManageEdtior',
        component: () => import('./design-task/views/editor/index.vue'),
        meta: {
          title: '妙想-款式详情-在线编辑',
          activeMenu: 'DesignTaskManageList',
        },
      },
      {
        path: 'sample-dress',
        name: 'SampleDress',
        component: () => import('@/modules/task-manage/sample-dress/views/list/index.vue'),
        meta: {
          title: '样衣',
          activeMenu: 'SampleDress',
        },
      },
    ],
  },
  {
    path: '/design-task-collect',
    name: 'DesignTaskManageCollect',
    component: () => import('@/modules/task-manage/design-task/views/collect/index.vue'),
  },
  {
    path: '/design-task-fail',
    name: 'DesignTaskFail',
    component: () => import('@/modules/task-manage/design-task/views/share-fail/index.vue'),
    meta: {
      auth: false,
    },
  },
];
