export enum SYSTEM_INFO_ENUM {
    COPYRIGHT = 'Copyright © 2021 广州致衣信息科技服务有限公司',
    SYSTEM_NAME = '妙款',
    SYSTEM_ENGLISH_NAME = 'Moken Management System'
}

export enum USER_TYPE_ENUM {
  /**
   * 真实用户
   */
  REAL = '1',
  /**
   * 虚拟用户
   */
  FAKER = '2',
}
