import { App, getCurrentInstance } from 'vue';
import SlsWebLogger from 'js-sls-logger';
import { cloneDeep } from 'lodash-es';
// eslint-disable-next-line import/no-cycle
// import { useAccountStore } from '@/store/account';
import { EVENT_TYPE_ENUM } from '@/core/plugins/slsLogger/constant';
import { currentEnv } from '@/core/http/env';
import { DOMAIN_ENV_ENUM } from 'cx-utils';

interface IServer{
  host: string;
  project: string;
  logstore: string;
  time: number;
  count: number;
}
interface IParams{
  [index: string]: any;
}
interface IHeatmap {
  clickMap: boolean;
  collectTags: string[];
}
interface IConfig {
  server?: IServer;
  params?: IParams;
  heatmap?: IHeatmap;
}

class SLSLogger {
  logger: any;

  app: any;

  win = <any>window;

  config: IConfig = {
    params: {
      // 用户id
      userId: '',
      // 用户名称
      userName: '',
      // 页面url
      pageurl: window.location.href,
      // 页面来源
      referer: document.referrer,
      // 屏幕高度
      screenHeight: window.screen.height,
      // 屏幕宽度
      screenWidth: window.screen.width,
      // 事件发生时间（本地时间，不准确时间，只作参考）
      time: new Date(),
      // 用户标识
      userAgent: window.navigator.userAgent,
      // 页面导航路径
      pathName: '',
      // 事件类型
      eventType: '',
      // 事件详情
      eventDetails: {
        // 事件名称
        eventName: '',
      },
    },
    heatmap: {
      clickMap: true,
      collectTags: [],
    },
    server: {
      host: '',
      project: '',
      logstore: '',
      time: 1,
      count: 1,
    },
  };

  /** 页面进入时间戳 */
  private _timePageEntry: number = 0;

  /**
   * 初始化日志
   * @param config
   */
  init(app: App, config: IConfig) {
    this.app = app;
    this.config.heatmap = Object.assign(this.config.heatmap, config.heatmap);
    this.config.server = Object.assign(this.config.server, config.server);
    this.config.params = Object.assign(this.config.params, config.params);
    this.logger = new SlsWebLogger(this.config.server);
    // 挂载到window对象
    try {
      this.win.logger = this;
      this.initHeatmap();
      this.initPV();
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * 更新参数
   * @param params
   */
  updateParams(params: IParams) {
    this.config.params = Object.assign(this.config.params, params);
  }

  /**
   * 初始化
   */
  initPV() {
    if ('onhashchange' in window) {
      // 将近期缓存的页面停留时长数据上报;
      window.addEventListener('load', () => {
        if (currentEnv === DOMAIN_ENV_ENUM.prod) {
          const tpLatest = localStorage.getItem('Tgo_app_stay');
          localStorage.removeItem('Tgo_app_stay');
          if (tpLatest !== null && typeof tpLatest === 'string') {
            const list = JSON.parse(tpLatest);
            list.forEach((item: IConfig['params']) => {
              this.logger.send({
                ...item,
                eventType: EVENT_TYPE_ENUM.PAGE_STAY,
              });
            });
          }
        }
      });

      // 缓存页面停留时长
      window.addEventListener('unload', () => {
        if (currentEnv === DOMAIN_ENV_ENUM.prod) {
          const now = new Date().getTime();
          const tpLatest = localStorage.getItem('Tgo_app_stay');
          const data = tpLatest ? JSON.parse(tpLatest) : [];
          data.push({
            ...this.config.params,
            pathName: this.getPathTile(),
            pageurl: window.location.href,
            time: new Date().getTime(),
            eventType: EVENT_TYPE_ENUM.PAGE_STAY,
            eventDetails: {
              duration: (now - this._timePageEntry) / 1000,
              eventName: '页面停留',
            },
          });
          localStorage.setItem('Tgo_app_stay', JSON.stringify(data));
        }
      });

      // const onhashchangeTemp = this.win.onhashchange;
      // slsLogger.win.onhashchange = function () {
      //   if (onhashchangeTemp) onhashchangeTemp();
      //   slsLogger.entryPage();
      // };

      // eslint-disable-next-line no-restricted-globals
      // const pushStateTemp = history.pushState;
      // window.history.pushState = function (data: any, title: string, url?: string | null) {
      //   if (pushStateTemp) pushStateTemp(data, title, url);
      //   slsLogger.entryPage(data, title, url);
      // };
      // const onbeforeunloadTemp = this.win.onbeforeunload;
      // slsLogger.win.onbeforeunload = function () {
      //   if (onbeforeunloadTemp) onbeforeunloadTemp();
      //   slsLogger.livePage();
      // };
    }
  }

  /**
   * 初始化
   */
  initHeatmap() {
    // const tags = ['div'];
    const html = document.getElementsByTagName('html')[0];
    const getParentNodeName = (node: Element): string[] => {
      let nodePath: string[] = [];
      if (node.parentNode) {
        nodePath = getParentNodeName(node);
      }
      nodePath.unshift(node.nodeName);
      return nodePath;
    };
    const getClassPath = (el: HTMLElement) => {
      const classPath: string[] = [];
      el.classList.forEach(item => classPath.push(item));
      let parentNode: HTMLElement = el;
      while (parentNode !== null) {
        (parentNode as HTMLElement).classList?.forEach(item => classPath.push(item as string));
        parentNode = parentNode.parentNode as HTMLElement;
      }
      return classPath;
    };
    html.addEventListener('click', (event: MouseEvent) => {
      const ev: Event = event || window.event;
      // const target: (EventTarget | null) = ev.target || ev.srcElement;
      // (ev.target as HTMLElement).classList.forEach(item => item)
      const classPath = getClassPath(ev.target as HTMLElement);
      const path = ev.composedPath().map((dom: any) => {
        if (!dom.nodeName) {
          return '';
        }
        return dom.nodeName.toLowerCase();
      });
      if (this.config.heatmap && this.config.heatmap.clickMap) {
        this.config.heatmap?.collectTags.forEach((item: string) => {
          if (path.includes(item) || classPath.includes(item.replace('.', ''))) {
            const opts = {
              eventType: EVENT_TYPE_ENUM.CLICK,
              eventDetails: {
                eventName: item,
                classPath: `${classPath}`,
                classList: (ev.target as HTMLElement).classList,
                nodePath: path,
                text: (ev.target as any).innerText,
              },
            };
            // 判断是否为开关 并判断状态
            if (classPath.includes('el-switch')) {
              opts.eventDetails.text = classPath.includes('is-checked') ? '点击时开启' : '点击时关闭';
            }
            this.send(opts);
          }
        });
      }
    }, true);
  }

  /**
   * 进入页面
   */
  entryPage(referer: string) {
    this._timePageEntry = new Date().getTime();
    this.send({
      referer,
      eventType: EVENT_TYPE_ENUM.PV,
      eventDetails: {
        eventName: '',
      },
    });
  }

  /**
   * 离开页面
   */
  livePage() {
    if (this._timePageEntry > 0) {
      const now = new Date().getTime();
      this.send({
        eventType: EVENT_TYPE_ENUM.PAGE_STAY,
        eventDetails: {
          eventName: '页面停留',
          duration: (now - this._timePageEntry) / 1000,
        },
      });
    }
    this._timePageEntry = 0;
  }

  /**
   * 路由名称
   */
  getPathTile() {
    const list = this.app.config.globalProperties.$route.matched.map((item: any) => item.meta.title);
    return list[list.length - 1];
  }

  /**
   * 发送埋点
   * @param opts
   */
  send(opts?: any) {
    const _opts = Object.assign(cloneDeep(this.config.params), {
      pathName: this.getPathTile(),
      pageurl: window.location.href,
      time: new Date().getTime(),
    }, opts || {});
    if (currentEnv === DOMAIN_ENV_ENUM.prod) {
      this.logger.send(_opts);
    } else {
      // this.logger.send(_opts);
      console.log(_opts);
    }
  }

  /**
   * 安装函数
   * @param app
   * @param config
   */
  install(app: App, config: IConfig) {
    this.init(app, config);
    const logger = SLSLogger.getLogger();
    // 挂载logger 对象
    app.config.globalProperties.$logger = logger;
    // 自定义log指令
    app.directive('log', {
      mounted(el, binding) {
        el.addEventListener('click', () => {
          const data = Object.assign({
            eventType: EVENT_TYPE_ENUM.CLICK,
            eventDetails: {
              eventName: '',
            },
          }, binding.value);
          logger.send(data);
        }, true);
      },
    });
    // 快捷设置eventDetails事件详情
    app.directive('log-details', {
      mounted(el, binding) {
        el.addEventListener('click', () => {
          logger.send({
            eventType: EVENT_TYPE_ENUM.CLICK,
            eventDetails: binding.value,
          });
        }, true);
      },
    });
    // 快捷设置eventDetails事件详情
    app.directive('log-details-name', {
      mounted(el, binding) {
        el.addEventListener('click', () => {
          logger.send({
            eventType: EVENT_TYPE_ENUM.CLICK,
            eventDetails: {
              eventName: binding.value,
            },
          });
        }, true);
      },
    });
    // 初始化用户信息
    // this.initUserInfo();
  }

  /**
   * 获取单例
   */
  static getLogger() {
    const globalProperties = getCurrentInstance()?.appContext.config?.globalProperties;
    const logger = globalProperties?.$logger;
    if (logger) {
      return logger;
    }
    if ('logger' in window) {
      return (window as any).logger;
    }
    return new SLSLogger();
  }

  /**
   * 发送埋点
   * @param eventType
   * @param opts
   */
  // eslint-disable-next-line class-methods-use-this
  sendDetails(eventType: EVENT_TYPE_ENUM, opts: any) {
    const logger = SLSLogger.getLogger();
    const data = {
      eventType: eventType || '',
      eventDetails: opts,
    };
    logger?.send(
      Object.assign({ opportunity: 'before' }, data),
    );
  }

  /**
   * 发送埋点
   * @param eventType
   * @param name
   */
  // eslint-disable-next-line class-methods-use-this
  sendDetailsName(eventType: EVENT_TYPE_ENUM, name: string) {
    const logger = SLSLogger.getLogger();
    const data = {
      eventType: eventType || '',
      eventDetails: {
        eventName: name,
      },
    };
    logger?.send(
      Object.assign({ opportunity: 'before' }, data),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  // initUserInfo() {
  //   const accountStore = useAccountStore();
  //   SLSLogger.getLogger().updateParams({
  //     userId: String(accountStore.account?.userId),
  //     userName: String(accountStore.account?.username),
  //   });
  //   watch(() => accountStore.account, (value) => {
  //     SLSLogger.getLogger().updateParams({
  //       userId: String(value?.userId),
  //       userName: String(value?.username),
  //     });
  //   });
  // }
}

export default new SLSLogger();
