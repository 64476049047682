
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'customSelect',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [String, Array],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup(_props, { emit }) {
    const visible = ref(false);
    const handleChangeVisible = (val: boolean) => {
      visible.value = val;
    };
    const handleChangeValue = (val: string|string[]) => {
      emit('update:modelValue', val);
    };
    return {
      handleChangeVisible,
      handleChangeValue,
      visible,
    };
  },
});
