import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createBlock(_component_el_pagination, _mergeProps(_ctx.$attrs, {
    background: "",
    layout: _ctx.layout,
    "page-sizes": _ctx.pageSizes,
    "page-size": _ctx.size,
    "pager-count": _ctx.pagerCount,
    onCurrentChange: _ctx.handleCurrentChange,
    onSizeChange: _ctx.handleSizeChange
  }), null, 16, ["layout", "page-sizes", "page-size", "pager-count", "onCurrentChange", "onSizeChange"]))
}