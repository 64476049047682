import taskManage from '@/modules/task-manage/route';
import settingManage from '@/modules/setting-manage/route';
import resourceManage from '@/modules/resource-manage/route';
import informationManage from '@/modules/information-manage/router';
import doupinManage from '@/modules/doupinku-manage/route';
import miaokuanManage from '@/modules/miaokuan-manage/route';

export default [
  ...taskManage,
  ...settingManage,
  ...resourceManage,
  ...informationManage,
  ...doupinManage,
  ...miaokuanManage,
];
