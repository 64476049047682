import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

import { FileUploadResponseItem } from './types';

export const uploadFile = (data: FormData, isNotCancel = true) => {
  return http.post<FileUploadResponseItem[]>({
    url: '/moken-portal/file/upload',
    data,
    loading: true,
    isNotCancel,
    isUploadFileApi: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
  });
};
