import { createApp, watch } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import filters from './core/plugins/filter';
import Element from 'element-plus';
import componentsInstall from '@/core/plugins/components-install';
import slsLogger from '@/core/plugins/slsLogger/SLSLogger';
import { useAccountStore } from '@/store/account';
import { EVENT_TYPE_ENUM } from '@/core/plugins/slsLogger/constant/index';
import { currentEnv } from '@/core/http/env';

import 'element-plus/packages/theme-chalk/lib/index.css';
import './core/plugins/permission';
import '@/styles/iconfont/index.scss';
import '@/styles/iconfont/iconfont';
import '@/styles/index.scss';
import 'swiper/swiper.min.css';

const logOpts = {
  server: {
    host: 'cn-hangzhou.log.aliyuncs.com',
    project: 'moken',
    logstore: 'moken',
    // host: 'cn-guangzhou.log.aliyuncs.com',
    // project: 'xj-log-test',
    // logstore: 'xj-log-test',
    time: 1,
    count: 1,
  },
  heatmap: {
    clickMap: true,
    collectTags: [],
  },
  params: {
    lib: 'js',
    objectName: '情报-MOKEN',
    version: 'v0.0.1',
    userId: '',
    userName: '',
    companyId: '',
    companyName: '',
    env: currentEnv,
  },
};

const app = createApp(App)
  .use(store)
  .use(router)
  .use(filters)
  .use(slsLogger, logOpts)
  .use(Element, {
    size: 'small',
  });

componentsInstall(app);
app.mount('#app');

// 更新埋点用户信息
const accountStore = useAccountStore();
slsLogger.updateParams({
  userId: String(accountStore.account?.userId),
  userName: String(accountStore.account?.username),
  companyId: String(accountStore.currentCompany?.companyId),
  companyName: String(accountStore.currentCompany?.companyName),
});
watch(() => accountStore.account, (value) => {
  slsLogger.updateParams({
    userId: String(value?.userId),
    userName: String(value?.username),
  });
  slsLogger.send({
    eventType: EVENT_TYPE_ENUM.LOGIN_STATE_CHANGE,
  });
});
watch(() => accountStore.currentCompany, (value) => {
  slsLogger.updateParams({
    companyId: String(value?.companyId),
    companyName: String(value?.companyName),
  });
  slsLogger.send({
    eventType: EVENT_TYPE_ENUM.LOGIN_STATE_CHANGE,
  });
});
