export default [
  {
    path: '/doupinku-manage',
    redirect: '/doupinku-manage/list',
    name: 'Doupinku',
    component: () => import('@/layouts/main.vue'),
    children: [
      {
        path: 'list',
        name: 'DoupinkuList',
        component: () => import('./views/list.vue'),
        meta: {
          title: '抖款-抖品库',
          activeMenu: 'DoupinkuList',
        },
      },
      {
        path: 'detail',
        name: 'DoupinkuDetail',
        component: () => import('./views/detail.vue'),
        meta: {
          title: '抖款-抖品库详情',
          activeMenu: 'DoupinkuList',
        },
      },
    ],
  },
];
