import { Env, DOMAIN_ENV_ENUM, PROCESS_ENV_ENUM } from 'cx-utils';

export const envInstance = new Env(
  process.env.env as PROCESS_ENV_ENUM,
  /* 本地环境 使用环境 */
  process.env.env || DOMAIN_ENV_ENUM.qa1,
  // DOMAIN_ENV_ENUM.qa2,
);

// 获取 api 前置路径
export const { API_BASE, currentEnv } = envInstance;
export const ENV_PREFIX = window.location.host;
