import { IMenuItem } from './types';
import {
  RESOURCE_TYPE_ENUM,
} from '@/api/menu/type';

export const shareMenuConfig: IMenuItem[] = [
  {
    resourceId: '1801',
    resourceName: '设计中心',
    resourceCode: '',
    resourceType: RESOURCE_TYPE_ENUM.CATALOGUE,
    frontEndUrl: 'DesignManage',
    disabled: true,
    icon: '',
    children: [{
      resourceId: '1804',
      resourceName: '妙想',
      resourceCode: '',
      resourceType: RESOURCE_TYPE_ENUM.CATALOGUE,
      frontEndUrl: 'DesignTaskManage',
      disabled: true,
      icon: '',
      children: [{
        resourceId: '1805',
        resourceName: '想法',
        resourceCode: '',
        resourceType: RESOURCE_TYPE_ENUM.MENU,
        frontEndUrl: 'DesignTaskManageList',
        disabled: true,
        icon: '',
        children: [{
          resourceId: '1830',
          resourceName: '查看款式详情',
          resourceCode: 'MOKEN-SJZX-RW-SJRW-CKKSXQ',
          resourceType: RESOURCE_TYPE_ENUM.BUTTON,
          frontEndUrl: '',
          icon: '',
          children: [],
        },
        {
          resourceId: '1831',
          resourceName: '通过',
          resourceCode: 'MOKEN-SJZX-RW-SJRW-TG',
          resourceType: RESOURCE_TYPE_ENUM.BUTTON,
          frontEndUrl: '',
          icon: '',
          children: [],
        },
        {
          resourceId: '1834',
          resourceName: '淘汰',
          resourceCode: 'MOKEN-SJZX-RW-SJRW-TT',
          resourceType: RESOURCE_TYPE_ENUM.BUTTON,
          frontEndUrl: '',
          icon: '',
          children: [],
        },
        {
          resourceId: '1835',
          resourceName: '待改',
          resourceCode: 'MOKEN-SJZX-RW-SJRW-DG',
          resourceType: RESOURCE_TYPE_ENUM.BUTTON,
          frontEndUrl: '',
          icon: '',
          children: [],
        },
        {
          resourceId: '1840',
          resourceName: '删除图片',
          resourceCode: 'MOKEN-SJZX-RW-SJRW-DELETETP',
          resourceType: RESOURCE_TYPE_ENUM.BUTTON,
          frontEndUrl: '',
          icon: '',
          children: [],
        },
        {
          resourceId: '1841',
          resourceName: '下载图片',
          resourceCode: 'MOKEN-SJZX-RW-SJRW-XZTP',
          resourceType: RESOURCE_TYPE_ENUM.BUTTON,
          frontEndUrl: '',
          icon: '',
          children: [],
        },
        {
          resourceId: '1845',
          resourceName: '添加/回复批注',
          resourceCode: 'MOKEN-SJZX-RW-SJRW-TJHFPZ',
          resourceType: RESOURCE_TYPE_ENUM.BUTTON,
          frontEndUrl: '',
          icon: '',
          children: [],
        },
        {
          resourceId: '1846',
          resourceName: '编辑、删除批注',
          resourceCode: 'MOKEN-SJZX-RW-SJRW-BJSCPZ',
          resourceType: RESOURCE_TYPE_ENUM.BUTTON,
          frontEndUrl: '',
          icon: '',
          children: [],
        },
        {
          resourceId: '1847',
          resourceName: '显示/隐藏批注',
          resourceCode: 'MOKEN-SJZX-RW-SJRW-XSYCPZ',
          resourceType: RESOURCE_TYPE_ENUM.BUTTON,
          frontEndUrl: '',
          icon: '',
          children: [],
        }],
      }],
    }],
  },
];
