import { App } from 'vue';
import IconSvg from './icon-svg.vue';
import type { SFCWithInstall } from '@/types/utils';

IconSvg.install = (app: App): void => {
  app.component(IconSvg.name, IconSvg);
};

const _IconSvg = IconSvg as SFCWithInstall<typeof IconSvg>;

export default _IconSvg;
