
import { defineComponent, PropType, ref, watch } from 'vue';
import { datefuns } from 'cx-utils';

export default defineComponent({
  name: 'MkDatePicker',
  emits: ['change', 'update:modelValue'],
  props: {
    modelValue: {
      type: [Date, Array, String] as PropType<string | Date | Date[]>,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    showValue: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const dateValue = ref('');
    watch(() => props.modelValue, () => {
      dateValue.value = props.modelValue as any;
    }, { immediate: true });
    const handleDateChange = (val: string | Date | Date[] | null) => {
      if ((Array.isArray(val) && val.some(date => date === null)) || val === null) {
        return;
      }
      emit('update:modelValue', val);
      emit('change', val);
    };
    return {
      handleDateChange,
      dateValue,
      datefuns,
    };
  },
});
