
import { defineComponent, ref, PropType } from 'vue';
import { ElPopover } from 'element-plus';

export default defineComponent({
  name: 'customDatePicker',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    startPlaceholder: {
      type: String,
      default: '开始日期',
    },
    endPlaceholder: {
      type: String,
      default: '结束日期',
    },
    showClearBtn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const popoverEl = ref<InstanceType<typeof ElPopover>>();
    const date = ref(['', '']);
    const visible = ref(false);
    const handleChangeVisible = (val: boolean) => {
      if (val) {
        date.value = props.modelValue;
      } else {
        date.value = ['', ''];
      }
      visible.value = val;
    };
    const handleConfirm = () => {
      popoverEl.value?.hide();
      emit('update:modelValue', date.value);
    };
    const handleClear = () => {
      date.value = ['', ''];
      emit('update:modelValue', date.value);
    };
    return {
      popoverEl,
      handleChangeVisible,
      visible,
      date,
      handleConfirm,
      handleClear,
    };
  },
});
